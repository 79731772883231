//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationSuccessTop from '@/components/System/Notifications/Success/NotificationSuccessTop';
import NotificationSuccessBody from '@/components/System/Notifications/Success/NotificationSuccessBody';

export default {
  name: 'NotificationSuccess',
  components: {
    NotificationSuccessTop,
    NotificationSuccessBody,
  },
}
