//
//
//
//
//
//
//
//

import NotificationReject from '@/components/System/Notifications/Reject/NotificationReject';
import NotificationSuccess from '@/components/System/Notifications/Success/NotificationSuccess';
import NotificationError from '@/components/System/Notifications/Error/NotificationError';


export default {
  name: 'TheNotifications',
  components: {
    NotificationReject,
    NotificationSuccess,
    NotificationError
  },
}
