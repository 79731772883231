var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'navigation-row',
    { 'disabled': _vm.link.isDisabled } ]},[(_vm.link.externalUrl)?_c('a',{class:[
      'navigation-row__link',
      { 'active-link': _vm.link.parentActive },
      { 'disabled': _vm.link.isDisabled } ],attrs:{"href":_vm.link.externalUrl,"target":"_blank"},on:{"click":_vm.closeMenu}},[_c('VIcon',{staticClass:"navigation-row__icon",attrs:{"name":_vm.link.icon,"width":20,"height":20}}),_vm._v(" "),(_vm.isOpened)?_c('div',{staticClass:"navigation-row__label"},[_vm._v("\n      "+_vm._s(_vm.link.label)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.link.comingSoon)?_c('VLabel',{staticClass:"navigation-row__soon-label",attrs:{"inner":_vm.$t('Coming soon')}}):_vm._e()],1):(_vm.link.url !== '#')?_c('nuxt-link',{class:[
      'navigation-row__link',
      { 'active-link': _vm.link.parentActive },
      { 'disabled': _vm.link.isDisabled } ],attrs:{"to":_vm.link.url,"active-class":"active"},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_c('VIcon',{staticClass:"navigation-row__icon",attrs:{"name":_vm.link.icon,"width":20,"height":20}}),_vm._v(" "),(_vm.isOpened)?_c('div',{staticClass:"navigation-row__label"},[_vm._v("\n      "+_vm._s(_vm.link.label)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.link.comingSoon)?_c('VLabel',{staticClass:"navigation-row__soon-label",attrs:{"inner":_vm.$t('Coming soon')}}):_vm._e()],1):_c('a',{class:[
      'navigation-row__link',
      { 'active-link': _vm.link.parentActive },
      { 'disabled': _vm.link.isDisabled } ],attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.handleLinkClick(_vm.link)}}},[_c('VIcon',{staticClass:"navigation-row__icon",attrs:{"name":_vm.link.icon,"width":20,"height":20}}),_vm._v(" "),(_vm.isOpened)?_c('div',{staticClass:"navigation-row__label"},[_vm._v("\n      "+_vm._s(_vm.link.label)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.link.comingSoon)?_c('VLabel',{staticClass:"navigation-row__soon-label",attrs:{"inner":_vm.$t('Coming soon')}}):_vm._e()],1),_vm._v(" "),(!_vm.isOpened && !_vm.link.isDisabled)?_c('VTooltip',{staticClass:"navigation-row__tooltip",attrs:{"tooltip":_vm.link.label}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }