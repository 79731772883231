//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationErrorTop from '@/components/System/Notifications/Error/NotificationErrorTop';
import NotificationErrorBody from '@/components/System/Notifications/Error/NotificationErrorBody';
  
  export default {
    name: 'NotificationError',
    components: {
        NotificationErrorTop,
        NotificationErrorBody,
    },
  }
  