//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import VIcon from '@/components/UI/VIcon';

import NavigationRow from '@/components/System/Navigation/NavigationRow/NavigationRow';
import Balance from '@/components/System/Header/Control/Balance/Balance';
import Account from '@/components/System/Header/Control/Account/Account';

import AppLanguages from '@/components/System/AppLanguages/AppLanguages';
import NavDropdown from '@/components/System/Navigation/NavDropdown/NavDropdown';

import { signOutRequest } from '@/requestManager/cabinet/main';

export default {
  name: 'NavigationMobile',
  components: {
    VIcon,
    NavigationRow,
    Balance,
    Account,
    AppLanguages,
    NavDropdown,
  },

  props: {
    navigationFirstList: Array,
    navigationSecondList: Array,
    navigationThirdList: Array,
    navigationForthList: Array,
    clientWindowWidth: Number,
  },
  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),

    ...mapState('general', [
      'languages',
    ]),

    navSubmenu() {
      return [
        {
          label: 'EARN',
          icon: 'EarnIcon',
          url: '/cabinet/services/earn',
          isDisabled: false,
          comingSoon: false,
        },
        {
          label: 'MINE',
          icon: 'MineIcon',
          url: '/cabinet/services/mine',
          isDisabled: true,
          comingSoon: true,
        },
        {
          label: 'INSURANCE',
          icon: 'InsuranceIcon',
          url: '/cabinet/services/insurance',
          isDisabled: true,
          comingSoon: true,
        },
      ]
    },
  },

  methods: {
    async triggerLogOutUser() {
      await signOutRequest(this.$cookies.get('accessToken'));

      this.$cookies.remove('accessToken');

      await this.$router.push({
        path: '/authorization/sign-in',
      });
    },
  },

  i18n: {
    messages: {
      en: {
        'Logout': 'Logout',
      },
      ru: {
        'Logout': 'Выйти',
      },
    },
  },
}
