//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex';

import { VueSlideToggle } from 'vue-slide-toggle';

import VIcon from '@/components/UI/VIcon';

import NavigationRow from '@/components/System/Navigation/NavigationRow/NavigationRow';

export default {
  name: 'NavDropdown',
  components: {
    VueSlideToggle,
    VIcon,
    NavigationRow,
  },

  props: {
    panel: String,
    panelIcon: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    dropdownIsOpened: false,
    windowWidth: 0,
  }),
  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),
  },

  methods: {
    ...mapMutations('system/toggleNavigation', [
      'CLOSE_NAVIGATION',
    ]),

    closeNavigation() {
      this.windowWidth = window.innerWidth;

      if (this.windowWidth < 1024) {
        this.CLOSE_NAVIGATION();
      }
    },
  },
}
