//
//
//
//
//
//

export default {
  name: 'NotificationErrorBody',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
