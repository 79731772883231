//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'NotificationSuccessTop',
  components: {
    VIcon,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
