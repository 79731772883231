//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';

import NavigationLaptop from '@/components/System/Navigation/Laptop/NavigationLaptop';
import NavigationMobile from '@/components/System/Navigation/Mobile/NavigationMobile';

import { getActiveLink } from '@/utils/mixins/RouteMixin';

export default {
  name: 'TheNavigation',
  components: {
    NavigationLaptop,
    NavigationMobile,
  },

  mixins: [
    getActiveLink,
  ],
  data: () => ({
    clientWindowWidth: 0,
    laptopMenu: true,
  }),
  computed: {
    navigationFirstList() {
      return [
        {
          label: this.$t('Main page'),
          icon: 'DashboardIcon',
          url: '/cabinet/dashboard',
          isDisabled: false,
        },
        {
          label: 'EARN',
          icon: 'EarnIcon',
          url: '/cabinet/services/earn',
          isDisabled: false,
          comingSoon: false,
        },
        {
          label: this.$t('Referral page'),
          icon: 'ReferralIcon',
          url: '/cabinet/referral',
          isDisabled: false,
          comingSoon: false,
        },
      ]
    },
    navigationSecondList() {
      return [
        {
          label: this.$t('Wallet page'),
          icon: 'WalletIcon',
          url: '/cabinet/wallet',
          isDisabled: false,
        },
      ]
    },
    navigationThirdList() {
      return [
        // {
        //   label: this.$t('News page'),
        //   icon: 'NewsIcon',
        //   url: '/cabinet/news',
        //   isDisabled: false,
        //   comingSoon: false,
        // },
        {
          label: this.$t('News page'),
          icon: 'NewsIcon',
          externalUrl: 'https://medium.com/@indefi.io', // External link to Medium
          isDisabled: false,
          comingSoon: false,
        },
        // {
        //   label: this.$t('Blog page'),
        //   icon: 'BlogIcon',
        //   url: '/cabinet/blog',
        //   isDisabled: true,
        //   comingSoon: true,
        // },
        {
          label: this.$t('FAQ page'),
          icon: 'FaqIcon',
          url: '/cabinet/faq',
          isDisabled: false,
        },
        {
          label: this.$t('Documents page'),
          icon: 'DocumentsIcon',
          url: '/cabinet/documents',
          isDisabled: false,
        },
        {
          label: this.$t('Support page'),
          icon: 'SupportIcon',
          url: '#',
          isDisabled: false,
          comingSoon: false,
          onClick: () => this.openChat(),
        },
      ]
    },
    navigationForthList() {
      return [
        {
          label: this.$t('Security page'),
          icon: 'SecurityIcon',
          url: '/cabinet/security/password',
          isDisabled: false,
          parentActive: this.securityLinksIsActive,
        },
        {
          label: this.$t('Settings page'),
          icon: 'SettingsIcon',
          url: '/cabinet/settings/profile',
          isDisabled: false,
          parentActive: this.settingsLinksIsActive,
        },
      ]
    },
  },

  mounted() {
    window.addEventListener('resize', this.checkTypeOfNavigation);
    this.checkTypeOfNavigation();
  },

  methods: {
    ...mapMutations('system/toggleNavigation', [
      'CLOSE_NAVIGATION',
    ]),
    checkTypeOfNavigation() {
      this.clientWindowWidth = window.innerWidth;

      if (this.clientWindowWidth > 1024) {
        this.laptopMenu = true
      } else {
        this.laptopMenu = false;
        this.CLOSE_NAVIGATION();
      }
    },
    loadTidioScript() {
      const existingScript = document.querySelector('script[src="//code.tidio.co/8wavfewstv1zwgscwv4h80dpepfkn5n4.js"]');
      if (existingScript) existingScript.remove();

      const script = document.createElement('script');
      script.src = "//code.tidio.co/8wavfewstv1zwgscwv4h80dpepfkn5n4.js";
      script.async = true;
      document.head.appendChild(script);
    },

    openChat() {
      if (window.tidioChatApi) {
        window.tidioChatApi.open();
      } else {
        document.addEventListener("tidioChat-ready", () => {
          window.tidioChatApi.open();
        });
        this.loadTidioScript();
      }
    }
  },

  i18n: {
    messages: {
      en: {
        'Main page': 'Dashboard',
        'Services page': 'Services',
        'Insurance page': 'Insurance',
        'Exchange page': 'Exchange',
        'Referral page': 'Referral program',
        'Wallet page': 'Wallet',
        'News page': 'News',
        'Blog page': 'Blog',
        'FAQ page': 'FAQ',
        'Documents page': 'Documents',
        'Support page': 'Support',
        'Security page': 'Security',
        'Settings page': 'Settings',
      },
      ru: {
        'Main page': 'Dashboard',
        'Services page': 'Services',
        'Reports page': 'Страхование',
        'Exchange page': 'Вывод',
        'Referral page': 'Реф. программа',
        'Wallet page': 'Мой кошелёк',
        'News page': 'Новости',
        'Blog page': 'Блог',
        'FAQ page': 'FAQ',
        'Documents page': 'Документы',
        'Support page': 'Служба поддержки',
        'Security page': 'Безопастность',
        'Settings page': 'Настройки',
      },
    },
  },
}
