//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import AppLanguages from '@/components/System/AppLanguages/AppLanguages';

import NavigationRow from '@/components/System/Navigation/NavigationRow/NavigationRow';
import NavDropdown from '@/components/System/Navigation/NavDropdown/NavDropdown';

export default {
  name: 'NavigationLaptop',
  components: {
    NavigationRow,
    AppLanguages,
    NavDropdown,
  },

  props: {
    navigationFirstList: Array,
    navigationSecondList: Array,
    navigationThirdList: Array,
    navigationForthList: Array,
  },
  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),
    ...mapState('general', [
      'languages',
    ]),

    navSubmenu() {
      return [
        {
          label: 'EARN',
          icon: 'EarnIcon',
          url: '/cabinet/services/earn',
          isDisabled: false,
          comingSoon: false,
        },
        {
          label: 'MINE',
          icon: 'MineIcon',
          url: '/cabinet/services/mine',
          isDisabled: true,
          comingSoon: true,
        },
        {
          label: 'INSURANCE',
          icon: 'InsuranceIcon',
          url: '/cabinet/services/insurance',
          isDisabled: true,
          comingSoon: true,
        },
      ]
    },
  },
}
