//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'NotificationErrorTop',
  components: {
    VIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
