//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';
import { mapState, mapMutations } from 'vuex';
import VTooltip from '@/components/UI/VTooltip';
import VLabel from '@/components/UI/VLabel';

export default {
  name: 'NavigationRow',
  components: {
    VIcon,
    VTooltip,
    VLabel,
  },

  props: {
    link: Object,
    clientWindowWidth: Number,
  },
  computed: {
    ...mapState('system/toggleNavigation', [
      'isOpened',
    ]),
  },

  methods: {
    ...mapMutations('system/toggleNavigation', [
      'TOGGLE_NAVIGATION',
    ]),

    closeMenu() {
      if (this.clientWindowWidth < 1024) {
        this.TOGGLE_NAVIGATION();
      }
    },
    handleLinkClick(link) {
    if (typeof link.onClick === 'function') {
      link.onClick();
    }
    this.closeMenu();
  }
  },

  i18n: {
    messages: {
      en: {
        'Coming soon': 'Soon',
      },
      ru: {
        'Coming soon': 'Скоро',
      },
    },
  },
}
